footer {
	padding: 10px;
	background: #000308;
	
}

footer p{
	color:#fff;
	
}

.img-link{
	opacity: 1;
}

.img-link:hover{
	opacity: 0.3;
	-webkit-transition:all 150ms ease;
	-moz-transition:all 150ms ease;
	-ms-transition:all 150ms ease;
	-o-transition:all 150ms ease;
	transition: all 150ms ease;
}

#social {
  list-style: none;
}

#social a {
  color: #fff;
}

#sponsor img{
	border-radius: 5px;
}

#sponsor a {
	color: #fff; 
	font-size: 22px;
}

#sponsor a:hover {
	text-decoration: none;
}

.icon-youtube:hover {
	color: #c4302b;
	text-decoration: none;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition: all 300ms ease;
}
.icon-twitter:hover {
	color: #00aced;
	text-decoration: none;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition: all 300ms ease;
}

.icon-facebook:hover {
	color: #3b5998;
	text-decoration: none;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition: all 300ms ease;
	
}

.icon-instagram:hover {
	color: #bc2a8d;
	text-decoration: none;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition: all 300ms ease;
	
}

#arriba:hover {
	color: #021e4f;
	font-size: 34px;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;
	transition: all 300ms ease;
}

