a img{
  border-radius: 5px;
}

a img:hover{
  opacity: 0.7;
  -webkit-transition:all 150ms ease;
	-moz-transition:all 150ms ease;
	-ms-transition:all 150ms ease;
	-o-transition:all 150ms ease;
	transition: all 150ms ease;
}
