.carousel__item--animated {
  animation-duration: 10s; 
	animation-name: ease;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	overflow: hidden;
}

@-webkit-keyframes ease {
  from {
  	transform: scale (1) ;}
  to {transform: scale(1.1);}
}

@keyframes ease {
 	from {transform: scale (1);}
 	 to {transform: scale(1.1);}	
}
