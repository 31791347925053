.nav_link {
  padding: 8px 10px;
  font-size: 17px;
}

.nav_link.active {
  background:rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 1px;
}

.nav_link:hover {
  color: #fff;
  background:rgba(255, 255, 255, 0.05);
  border-radius: 5px;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition: all 300ms ease;	
}

#castores.active{
  color: #fff;
  background: #2ec4b5;
}
#castores:hover{
  color: #fff;
  background: #2ec4b5;
}

#manada.active{
  color: #fff;
  background: #c9be27;
}
#manada:hover{
  color: #fff;
	background: #c9be27;
}

#tropa.active{
  color: #fff;
  background: #61bd49;
}
#tropa:hover{
  color: #fff;
  background: #61bd49;
}

#esculta:hover{
  color: #fff;
  background: #94420c;
}
#esculta.active{
  color: #fff;
  background: #94420c;
}

#clan:hover{
  color: #fff;
  background: #ad1002;
}
#clan.active{
  color: #fff;
  background: #ad1002;
}

#kraal:hover{
  color: #fff;
  background: #9e0e70;
}
#kraal.active{
  color: #fff;
  background: #9e0e70;
}
